import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { glsp, media } from '@devseed-ui/theme-provider';
import { Button } from '@devseed-ui/button';
import { CollecticonXmarkSmall } from '@devseed-ui/collecticons';

const Banner = styled.div`
  position: relative;
  z-index: 100;
  background-color: #1a5bdb;
  color: #fff;
  text-transform: uppercase;
  font-variation-settings: 'wdth' 32, 'wght' 600;
  display: flex;
  flex-flow: column;
  gap: ${glsp()};
  padding: ${glsp()};
  text-align: center;

  > p {
    display: flex;
    flex-flow: column;
  }

  ${media.mediumUp`
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;

    > p {
      flex-flow: row;
      gap: ${glsp(0.5)};
    }
  `}

  a,
  a:visited,
  a:not([class]),
  a:not([class]):visited {
    color: #46d6cd;
  }

  ${Button} {
    position: absolute;
    top: 50%;
    right: ${glsp()};
    transform: translate(0, -50%);
  }
`;

const BrandName = styled.span`
  display: flex;
  align-items: center;
  gap: ${glsp(0.5)};
  justify-content: center;
`;

export function useCalloutBanner({ id, disable }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const banner = window.sessionStorage.getItem(id);
    setVisible(banner !== 'false');
  }, []);

  const hide = useCallback(() => {
    window.sessionStorage.setItem(id, false);
    setVisible(false);
  }, []);

  return [disable ? false : visible, hide];
}

export default function CalloutBanner(props) {
  const { onCloseClick } = props;

  return (
    <Banner>
      <p>
        <BrandName>
          <svg
            width='24px'
            height='15px'
            viewBox='0 0 128 80'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M88 16L72 32V8L64 0L56 8V32L40 16H0V64H40L56 48V80H72V48L88 64H128V16H88ZM16 56H8V24H16V56ZM32 56H24V24H32V56ZM104 56H96V24H104V56ZM120 56H112V24H120V56Z'
              fill='#fff'
            />
          </svg>{' '}
          SatSummit 2024
        </BrandName>
        November 18 and 19, Lisbon
      </p>
      <a href='https://satsummit.io/2024-lisbon/'>Tickets Available Now</a>

      <Button size='small' variation='achromic-text' onClick={onCloseClick}>
        <CollecticonXmarkSmall title='Hide banner' meaningful />
      </Button>
    </Banner>
  );
}

CalloutBanner.propTypes = {
  onCloseClick: T.func
};
